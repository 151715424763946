  
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    min-width: 270px;
    background-color: #f6f6f6
  }

  h1, h2, h3 {
    color: #3d3d3d
  }

  :root {
  --primary: #172b4d;
  --linear: #73A9A0;
  --secondary: #799926;
  --shape: #E5E5E5;
  --confirm: var(--primary);
  --cancel: rgb(139 136 136);
  --delete: #aa1d1d;
  --attention: #FFC0C1;
  --black: #000;
  --weakBorder: #8080802e;
  --success: #2e7d32;
  }

  .inputGenericGlobalStyle{
    width: 200px ;
  }

  #toast{
    top: 0;
    left: 0;
    bottom: 0;
  }
  
  @media print {
    .no-print {
      display: none !important;
    }
    #print{
        font-size: 0.8rem !important;
        box-shadow:none;
        margin-top: -30px;
        padding-bottom: 0px !important;

    }
    h2{
        margin: 0px !important;
    }

    h3{
        margin: 0px !important;
    }

    p{
        margin: 9px;
    }
  }

  .containerListTablePagesGlobal {
    height: 65vh;
    width: 100%;
  }

  .containerButtonNewRegisterListTablePagesGlobal {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: right;
  }

  .containerActionButtonsListTablePagesGlobal {
    display: flex; 
    gap: 5px;
  }

  .cursorPointerGlobal {
    cursor: pointer;
  }
  
  .css-1knaqv7-MuiButtonBase-root-MuiButton-root:hover{
    opacity: 0.9;
    transition: 0.3s;
  }

  @media (max-width: 670px) {
    
    .css-1t5wrdm-MuiDataGrid-filterForm, .MuiDataGrid-filterForm {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        padding: 0 !important; 
        padding-bottom: 12px !important;
        gap: 15px;
    }

    .css-rne967-MuiFormControl-root-MuiDataGrid-filterFormDeleteIcon, .MuiDataGrid-filterFormDeleteIcon{
        margin-left: auto !important;
    }

    .css-q68e8e-MuiFormControl-root-MuiDataGrid-filterFormColumnInput , .MuiDataGrid-filterFormColumnInput{
        width: 80% !important;
    }

    .css-17vwkjt-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput, .MuiDataGrid-filterFormOperatorInput {
        width: 80% !important;
    }

    .css-1h08ml-MuiFormControl-root-MuiDataGrid-filterFormValueInput, .MuiDataGrid-filterFormValueInput {
        width: 80% !important;
    }

    @media (max-width: 330px){
      .css-154sxbz-MuiPaper-root-MuiDataGrid-paper {
        width: 100% !important;
        min-width: 240px !important;
        margin: 0 auto !important;
        padding: 0 !important; 
      }
    }
  }
